import React from "react"
import styled from "styled-components"

const csRow = ({ children, className }) => (
  <div className={className}>{children}</div>
)

const ChainsimRow = styled(csRow)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

export default ChainsimRow
