import React from "react"
import { Container, Row } from "react-bootstrap"
import ChainsimContainer from "./chainsim-container"
import { createUniformArray } from "./chainsim-full/chainsim_functions/MatrixFunctions"

const DrillCategory = props => {
  const thumbnailData = props.drills.map(drill => {
    return {
      puyo: drill.target,
      shadow: createUniformArray("0", 6, 13),
      arrow: createUniformArray("0", 6, 13),
      cursor: createUniformArray("0", 6, 13),
      number: createUniformArray("0", 6, 13),
    }
  })

  return (
    <Container>
      <Row className="justify-content-center">
        {props.drills &&
          thumbnailData.map((data, i) => (
            <ChainsimContainer
              json={data}
              title={props.drills[i].title}
              link={props.drills[i].page}
              key={`${props.drills[i].title}-${props.drills[i].date}`}
            />
          ))}
      </Row>
    </Container>
  )
}

export default DrillCategory
