import { Puyo, PuyoType } from "./Puyo"

/**
 * Transposes matrices of any data type
 * @param {any[][]} inputMatrix
 */
export function transposeMatrix(inputMatrix) {
  const transpose = []
  for (let y = 0; y < inputMatrix[0].length; y++) {
    transpose[y] = []
    for (let x = 0; x < inputMatrix.length; x++) {
      transpose[y][x] = inputMatrix[x][y]
    }
  }

  return transpose
}

/**
 * Creates a 2D array with the same value in every cell
 * @param {any} value
 * @param {number} cols
 * @param {number} rows
 */
export function createUniformArray(value, cols, rows) {
  const matrix = []
  for (let x = 0; x < cols; x++) {
    matrix[x] = []
    for (let y = 0; y < rows; y++) {
      matrix[x][y] = value
    }
  }

  return matrix
}

/**
 * Creates a 2D array with empty Puyos in every cell
 * @param {number} cols
 * @param {number} rows
 */
export function createEmptyPuyoArray(cols, rows) {
  const matrix = []
  for (let x = 0; x < cols; x++) {
    matrix[x] = []
    for (let y = 0; y < rows; y++) {
      matrix[x][y] = new Puyo("0", x, y)
    }
  }

  return matrix
}

export function convertPuyoToStringArray(inputMatrix) {
  const matrix = []
  for (let x = 0; x < inputMatrix.length; x++) {
    matrix[x] = []
    for (let y = 0; y < inputMatrix[x].length; y++) {
      matrix[x][y] = inputMatrix[x][y].p
    }
  }

  return matrix
}

export function copyPrimitiveArray(inputMatrix) {
  const matrix = []
  for (let x = 0; x < inputMatrix.length; x++) {
    matrix[x] = []
    for (let y = 0; y < inputMatrix[x].length; y++) {
      matrix[x][y] = inputMatrix[x][y]
    }
  }

  return matrix
}

/**
 * Coerces imported matrix if it doesn't fit the dimensions defined by simulator settings.
 * @param {string[][]} inputMatrix
 * @param {SimulatorSettings} settings
 */
export function coercePuyoMatrix(inputMatrix, settings) {
  const matrix = createEmptyPuyoArray(settings.cols, settings.rows)

  if (inputMatrix[0].length < settings.rows) {
    // If inputMatrix is shorter than the matrix defined by settings,
    // shift the cells down to the new bottom of the matrix
    for (let x = 0; x < inputMatrix.length; x++) {
      for (let y = 0; y < inputMatrix[x].length; y++) {
        matrix[x][y + settings.rows - inputMatrix[x].length] = new Puyo(
          inputMatrix[x][y],
          x,
          y + settings.rows - inputMatrix[x].length
        )
      }
    }
  } else if (inputMatrix[0].length > settings.rows) {
    // If inputMatrix is larger than the matrix defined by settings,
    // shift the cells up to the new bottom of the matrix
    for (let x = 0; x < settings.cols; x++) {
      for (let y = 0; y < settings.rows; y++) {
        if (inputMatrix.length <= settings.cols && x < inputMatrix.length) {
          matrix[x][y] = new Puyo(inputMatrix[x][y + inputMatrix[0].length - settings.rows], x, y)
        } else {
          matrix[x][y] = new Puyo(PuyoType.None, x, y)
        }
      }
    }
  } else {
    // If the heights are the same, just copy in place
    for (let x = 0; x < settings.cols; x++) {
      for (let y = 0; y < settings.rows; y++) {
        if (inputMatrix.length <= settings.cols && x < inputMatrix.length) {
          matrix[x][y] = new Puyo(inputMatrix[x][y], x, y)
        }
      }
    }
  }

  return matrix
}

export function resetArrayUniformly(arr, value) {
  for (let x = 0; x < arr.length; x++) {
    for (let y = 0; y < arr[0].length; y++) {
      arr[x][y] = value
    }
  }
}

export function rebuild2DArrayFromString(fieldString, settings) {
  const matrix = []
  for (let x = 0; x < settings.cols; x++) {
    matrix[x] = []
    for (let y = 0; y < settings.rows; y++) {
      matrix[x][y] = fieldString[x * settings.rows + y]
    }
  }

  return matrix
}

