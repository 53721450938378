export const PuyoType = {
  Red: "R",
  Green: "G",
  Blue: "B",
  Yellow: "Y",
  Purple: "P",
  Garbage: "J",
  Hard: "H",
  Point: "N",
  Stone: "T",
  Sun: "S",
  Block: "L",
  None: "0",
}

export function isColored(color) {
  switch (color) {
    case PuyoType.Red:
    case PuyoType.Green:
    case PuyoType.Blue:
    case PuyoType.Yellow:
    case PuyoType.Purple:
      return true
    default:
      return false
  }
}

export class Puyo {
  /**
   * A Puyo data object representing a field cell. Used in Field.js
   * @param {string} color - 1 letter character from PuyoType
   * @param {number} x - Column position of the Puyo
   * @param {number} y - Row position of the Puyo
   * @param {number} newY - Row position after expected drop
   * @param {string} connections - Connection directions
   */
  constructor(color, x, y) {
    this.p = color
    this.x = x
    this.y = y
    this.newY = y
    this.connections = "n"
  }

  get isEmpty() {
    return this.p === PuyoType.None
  }

  get isColored() {
    return (
      this.p === PuyoType.Red ||
      this.p === PuyoType.Green ||
      this.p === PuyoType.Blue ||
      this.p === PuyoType.Yellow ||
      this.p === PuyoType.Purple
    )
  }

  get isGarbage() {
    return this.p === PuyoType.Garbage || this.p === PuyoType.Hard
  }

  get isBlock() {
    return this.p === PuyoType.Block
  }

  get isStone() {
    return this.p === PuyoType.Stone
  }

  /**
   * Converts 1 letter color code to full name for use in selecting the correct sprite filenames.
   * @return {string} The "full" name of the cell's color
   */
  get name() {
    switch (this.p) {
      case PuyoType.Red:
        return "red"
      case PuyoType.Green:
        return "green"
      case PuyoType.Blue:
        return "blue"
      case PuyoType.Yellow:
        return "yellow"
      case PuyoType.Purple:
        return "purple"
      case PuyoType.Garbage:
        return "garbage"
      case PuyoType.Hard:
        return "hard"
      case PuyoType.Block:
        return "block"
      case PuyoType.Stone:
        return "stone"
      default:
        return "spacer"
    }
  }
}
