import { PuyoType } from "../chainsim-full/chainsim_functions/Puyo"
import { createUniformArray } from "../chainsim-full/chainsim_functions/MatrixFunctions"
import CoreSettings from "../chainsim-full/chainsim_functions/CoreSettings"

const settings = new CoreSettings()

const buildHistoryFromProps = props => {
  let json
  if (props.json === undefined) {
    json = {
      title: "",
      author: "",
      date: "",
      category: "",
      description: "",
      outputFolder: "",
      chainLength: 0,
      tags: [],
      seed: Math.round(Math.random() * 65535 * 2424),
      gameColorCount: 4,
      gameColors: [
        PuyoType.Red,
        PuyoType.Green,
        PuyoType.Blue,
        PuyoType.Yellow,
      ],
      overrideQueue: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      fields: [
        {
          comment: "",
          puyo: createUniformArray("0", settings.cols, settings.rows),
          shadow: createUniformArray("0", settings.cols, settings.rows),
          arrow: createUniformArray("0", settings.cols, settings.rows),
          cursor: createUniformArray("0", settings.cols, settings.rows),
          number: createUniformArray("0", settings.cols, settings.rows),
          placement: { coord: [], colors: [] },
          queuePosition: 0,
        },
      ],
    }
  } else {
    // check if a nested json was imported or not (for backwards compatibility purposes)
    if (props.json.fields === undefined) {
      json = {
        title: "",
        author: "",
        date: "",
        category: "",
        description: "",
        outputFolder: "",
        chainLength: 0,
        tags: [],
        seed: Math.round(Math.random() * 65535 * 2424),
        gameColorCount: 4,
        gameColors: [
          PuyoType.Red,
          PuyoType.Green,
          PuyoType.Blue,
          PuyoType.Yellow,
        ],
        overrideQueue: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        fields: [
          {
            comment: "",
            puyo: props.json.puyo,
            shadow: props.json.shadow,
            arrow: props.json.arrow,
            cursor: props.json.cursor,
            number: props.json.number
              ? props.json.number
              : createUniformArray("0", settings.cols, settings.rows),
            placement: { coord: [], colors: [] },
            queuePosition: 0,
          },
        ],
      }
    } else {
      json = props.json
    }
  }

  return json
}

export default buildHistoryFromProps
