// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-drill-layout-js": () => import("/opt/build/repo/src/components/drill-layout.js" /* webpackChunkName: "component---src-components-drill-layout-js" */),
  "component---src-components-guide-layout-js": () => import("/opt/build/repo/src/components/guide-layout.js" /* webpackChunkName: "component---src-components-guide-layout-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-drills-js": () => import("/opt/build/repo/src/pages/drills.js" /* webpackChunkName: "component---src-pages-drills-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listdrilltest-js": () => import("/opt/build/repo/src/pages/listdrilltest.js" /* webpackChunkName: "component---src-pages-listdrilltest-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-simulator-js": () => import("/opt/build/repo/src/pages/simulator.js" /* webpackChunkName: "component---src-pages-simulator-js" */),
  "component---src-pages-slideshowtest-js": () => import("/opt/build/repo/src/pages/slideshowtest.js" /* webpackChunkName: "component---src-pages-slideshowtest-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

