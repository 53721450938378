import React from "react"
import styled from "styled-components"

const gfRow = ({ children, className }) => (
  <div className={className}>{children}</div>
)

const GuideFigureRow = styled(gfRow)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`

export default GuideFigureRow
