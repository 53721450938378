import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

const Video = styled.video`
  width: 100%;
  height: auto;
  object-fit: fill;
`

const LocalVideoPlayer = ({ vidsrc }) => (
  <Container className="mb-4">
    <Row className="justify-content-center">
      <Col md={8}>
        <Video controls className="mb-4">
          <source src={vidsrc} type="video/mp4" />
        </Video>
      </Col>
    </Row>
  </Container>
)

export default LocalVideoPlayer
