import React, { useState, useRef, useEffect } from "react"
import esportsBackground from "../../../static/sim_assets/img/espo_bg.jpg"
import buildHistoryFromProps from "./buildHistoryFromProps"
// import { coercePuyoMatrix } from "../chainsim-full/MatrixFunctions"
import styled from "styled-components"
import { Card, Container, Row, Col } from "react-bootstrap"
// import { Link } from "gatsby"
import { coercePuyoMatrix } from "../chainsim-full/chainsim_functions/MatrixFunctions"
import CoreSettings from "../chainsim-full/chainsim_functions/CoreSettings"

const THUMBNAIL_HEIGHT = 445
const THUMBNAIL_WIDTH = 218

const SLIDESHOW_HEIGHT = 445
const SLIDESHOW_WIDTH = 316

const settings = new CoreSettings()

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CaptionBox = styled.div`
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px;
  margin-bottom: 8px;
  line-height: 1.2em;
  width: 100%;
  text-align: center;
`

const LoadingBox = styled.div`
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px black;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 15%;
  margin-top: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  .load-wheel {
    width: 40px;
    height: 40px;
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

const ChainsimContainer = props => {
  const [isGenerated, setIsGenerated] = useState(false)
  const [knowsPageLoaded, setKnowsPageLoaded] = useState(false)
  const [json] = useState(buildHistoryFromProps(props))
  const [holdingChainsim, setHoldingChainsim] = useState(false)
  const [caption, setCaption] = useState(
    props.caption !== undefined ? props.caption : json.fields[0].comment
  )
  const [useCaption] = useState(props.caption || props.slideshow || props.title)
  const [slide, setSlide] = useState(0)
  const [imgsrc, setImgsrc] = useState("")
  const gameNode = useRef(null)
  const gameContainer = useRef(null)
  const isMounted = useRef(false)
  // const cvs = useRef(null)

  const simHeight = props.slideshow ? SLIDESHOW_HEIGHT : THUMBNAIL_HEIGHT
  const simWidth = props.slideshow ? SLIDESHOW_WIDTH : THUMBNAIL_WIDTH

  const cardStyle = {
    backgroundColor: "#E8E8E8",
    backgroundImage: `url(${esportsBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: `${simWidth + 8}px`,
    padding: `8px`,
    boxSizing: `content-box`,
    margin: `0 4px 8px 4px`,
    WebkitBoxShadow: holdingChainsim ? `0px 4px 9px 0px rgba(0,0,0,0.7)` : ``,
    boxShadow: holdingChainsim ? `0px 4px 9px 0px rgba(0,0,0,0.7)` : ``,
  }

  const createThumbnail = () => {
    const setChainsimState = () => {
      window.chainsim.game.state.game.paused = true
      window.chainsim.game.state.history = json
      window.chainsim.game.state.next.currentQueuePosition = 0
      // window.chainsim.game.refreshFieldFromHistory(0)
      window.chainsim.game.chainsim.inputMatrix = json.fields[0].puyo
      window.chainsim.game.chainsim.updateFieldMatrix(json.fields[0].puyo)
      window.chainsim.game.state.field.shadow = coercePuyoMatrix(
        json.fields[0].shadow,
        settings
      )
      window.chainsim.game.state.field.arrow = JSON.parse(
        JSON.stringify(json.fields[0].arrow)
      )
      window.chainsim.game.state.field.cursor = JSON.parse(
        JSON.stringify(json.fields[0].cursor)
      )
      window.chainsim.game.state.field.number = JSON.parse(
        JSON.stringify(json.fields[0].number)
      )
      window.chainsim.game.resetFieldAndState()
      window.chainsim.game.generateColorQueue()
      window.chainsim.game.refreshActivePairSprites()
      window.chainsim.game.refreshNextPuyoSprites()
      window.chainsim.game.app.view.width = simWidth * 2
      requestAnimationFrame(generateThumbnail)
    }

    const generateThumbnail = () => {
      let resizedCanvas = document.createElement("canvas")
      // const resizedContext = cvs.current.getContext("2d")
      let resizedContext = resizedCanvas.getContext("2d")
      resizedContext.imageSmoothingEnabled = false
      // cvs.current.height = simHeight
      // cvs.current.width = simWidth
      resizedCanvas.height = simHeight
      resizedCanvas.width = simWidth
      resizedContext.drawImage(
        window.chainsim.game.app.view,
        0,
        0,
        simWidth,
        simHeight
      )
      if (isMounted.current) {
        setIsGenerated(true)
      }

      if (isMounted.current) {
        setImgsrc(resizedCanvas.toDataURL("image/png"))
      }

      window.chainsim.game.state.game.paused = false

      resizedCanvas = null
      resizedContext = null

      // Start the next thumbnail generation in the queue
      if (window.thumbnailQueue && window.thumbnailQueue.length > 1) {
        window.thumbnailQueue.splice(0, 1)
        window.thumbnailQueue[0]()
      } else {
        window.thumbnailQueue = []
        // console.log("This.... is the end!")
        window.chainsimInUse = false
        window.loadedQueue.forEach(fn => {
          fn(true)
        })
        window.loadedQueue = []
      }
    }

    requestAnimationFrame(setChainsimState)
  }

  const safelySetKnowsPageLoaded = bool => {
    // Checks if the component is mounted before calling a state setting function.
    // React will complain about memory leaks without this.
    if (isMounted.current) {
      setKnowsPageLoaded(bool)
    }
  }

  useEffect(() => {
    isMounted.current = true

    const addToThumbnailQueue = () => {
      if (!window.thumbnailQueue) {
        // console.log(
        //   "Couldn't find thumbnailQueue, so this component will make it."
        // )
        window.thumbnailQueue = [createThumbnail]
        window.loadedQueue = [safelySetKnowsPageLoaded]
        // console.log(window.thumbnailQueue)
        // console.log(window.chainsimLoaded)
        // console.log(window.chainsimInUse)
        // console.log(window.chainsimLoaded && !window.chainsimInUse)
        if (window.chainsimLoaded && !window.chainsimInUse) {
          // console.log(
          //   "chainsim-floater isn't doing anything, so this component will take control"
          // )
          window.chainsimInUse = true
          window.thumbnailQueue[0]()
        } else {
          // console.log("chainsim-floater isn't ready yet.")
        }
      } else {
        // console.log(
        //   "thumbnailQueue already exists. Just push in the function to be run."
        // )
        window.thumbnailQueue.push(createThumbnail)
        window.loadedQueue.push(safelySetKnowsPageLoaded)
        if (window.chainsimLoaded && !window.chainsimInUse) {
          // console.log(
          //   "chainsim-floater was just idling. Take control and make thumbnail."
          // )
          window.chainsimInUse = true
          window.thumbnailQueue[0]()
        } else {
          // console.log("chainsim-floater isn't ready yet.")
        }
      }
    }

    addToThumbnailQueue()

    return () => {
      // if (isMounted.current) {
      //   console.log("Setting not generated.")
      //   setIsGenerated(false)
      // }
      isMounted.current = false
    }
  }, [])

  const removeFromOldNode = () => {
    if (isMounted.current) {
      setHoldingChainsim(false)
      setSlide(0)
      setCaption(
        props.caption !== undefined ? props.caption : json.fields[0].comment
      )
    }
  }

  const gotoPrevSlide = () => {
    if (!window.chainsimLoaded) return

    window.chainsim.game.resetFieldAndState()
    const activePair = window.chainsim.game.objects.activePair.puyo
    const coordArray = window.chainsim.game.state.field.coord
    const activeState = window.chainsim.game.state.activePair
    const pairContainer = window.chainsim.game.containers.activePair

    activeState.puyoCoord = {
      abs: [
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60,
        },
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60 * 2,
        },
      ],
      index: [{ x: 2, y: -1 }, { x: 2, y: -2 }],
    }

    pairContainer.addChild(activePair[0])
    pairContainer.addChild(activePair[1])
    activePair[0].position.set(0, 0)
    activePair[1].position.set(0, -1 * 60)
    activePair[0].scale.set(1, 1)
    activePair[1].scale.set(1, 1)
    activeState.orientation = 0
    pairContainer.visible = true

    if (window.chainsim.game.state.next.currentQueuePosition / 2 > 0) {
      window.chainsim.game.refreshFieldFromHistory(
        (window.chainsim.game.state.next.currentQueuePosition - 2) / 2
      )
      window.chainsim.game.state.next.currentQueuePosition -= 2
      window.chainsim.game.refreshActivePairSprites()
      window.chainsim.game.refreshNextPuyoSprites()
    }

    const newCaption =
      slide > 0 ? json.fields[slide - 1].comment : json.fields[0].comment
    const newSlide = slide > 0 ? slide - 1 : 0

    setCaption(newCaption)
    setSlide(newSlide)
  }

  const gotoNextSlide = () => {
    if (!window.chainsimLoaded) return

    window.chainsim.game.resetFieldAndState()

    const activePair = window.chainsim.game.objects.activePair.puyo
    const coordArray = window.chainsim.game.state.field.coord
    const activeState = window.chainsim.game.state.activePair
    const pairContainer = window.chainsim.game.containers.activePair

    activeState.puyoCoord = {
      abs: [
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60,
        },
        {
          x: coordArray[2][0].x,
          y: coordArray[2][0].y - 60 * 2,
        },
      ],
      index: [{ x: 2, y: -1 }, { x: 2, y: -2 }],
    }

    pairContainer.addChild(activePair[0])
    pairContainer.addChild(activePair[1])
    activePair[0].position.set(0, 0)
    activePair[1].position.set(0, -1 * 60)
    activePair[0].scale.set(1, 1)
    activePair[1].scale.set(1, 1)
    activeState.orientation = 0
    pairContainer.visible = true

    if (
      window.chainsim.game.state.next.currentQueuePosition / 2 <
      window.chainsim.game.state.history.fields.length - 1
    ) {
      window.chainsim.game.refreshFieldFromHistory(
        (window.chainsim.game.state.next.currentQueuePosition + 2) / 2
      )
      window.chainsim.game.state.next.currentQueuePosition += 2
      window.chainsim.game.refreshActivePairSprites()
      window.chainsim.game.refreshNextPuyoSprites()
    }

    const newCaption =
      slide < json.fields.length - 1
        ? json.fields[slide + 1].comment
        : json.fields[json.fields.length - 1].comment
    const newSlide =
      slide < json.fields.length - 1 ? slide + 1 : json.fields.length - 1
    setCaption(newCaption)
    setSlide(newSlide)
  }

  const grabChainsim = () => {
    if (window.chainsimInUse) {
      return
    }

    if (!holdingChainsim) {
      // Resize canvas based on chainsim type (e.g. slideshow)
      window.chainsim.game.app.view.style.height = `${simHeight}px`
      window.chainsim.game.app.view.style.width = `${simWidth}px`

      // Pre-reset the chainsim in case it was playing already before
      // being moved.
      window.chainsim.game.resetFieldAndState()

      // Remove the chainsim from the previous chainsim it was in.
      window.chainsim.game.removeFromOldNode()

      // Append chainsim to this component, and set holding state to true.
      // Also set the current slide number back to 0
      gameNode.current.appendChild(window.chainsim.game.app.view)
      setHoldingChainsim(true)
      setSlide(0)

      // Set new removeFromOldNode callback
      window.chainsim.game.removeFromOldNode = removeFromOldNode

      // Set canvas viewport to container's width
      window.chainsim.game.app.view.width = simWidth * 2

      window.chainsim.game.state.history = json
      window.chainsim.game.state.next.currentQueuePosition = 0
      window.chainsim.game.chainsim.inputMatrix = json.fields[0].puyo
      // window.chainsim.game.refreshFieldFromHistory(slide)
      window.chainsim.game.chainsim.updateFieldMatrix(json.fields[0].puyo)
      window.chainsim.game.state.field.shadow = coercePuyoMatrix(
        json.fields[0].shadow,
        settings
      )
      window.chainsim.game.state.field.arrow = JSON.parse(
        JSON.stringify(json.fields[0].arrow)
      )
      window.chainsim.game.state.field.cursor = JSON.parse(
        JSON.stringify(json.fields[0].cursor)
      )
      window.chainsim.game.resetFieldAndState()
      window.chainsim.game.generateColorQueue()
      window.chainsim.game.refreshActivePairSprites()
      window.chainsim.game.refreshNextPuyoSprites()

      if (!window.chainsim.game.state.simulator.started && !props.slideshow) {
        window.chainsim.game.state.simulator.autoAdvance = true
        window.chainsim.game.state.simulator.started = true
        window.chainsim.game.state.simulator.speed = 1
        window.chainsim.game.simulateLink()
      } else if (props.slideshow) {
        gotoNextSlide()
      }
    }
  }

  const playSim = () => {
    if (!window.chainsimLoaded || !holdingChainsim || window.chainsimInUse) {
      return
    }

    if (
      window.chainsim.game.state.simulator.isChaining &&
      window.chainsim.game.state.simulator.started
    ) {
      window.chainsim.game.state.simulator.speed *= 2
    } else if (
      !window.chainsim.game.state.simulator.isChaining &&
      window.chainsim.game.state.simulator.started
    ) {
      window.chainsim.game.resetFieldAndState()
      window.chainsim.game.refreshFieldFromHistory(slide)
    } else {
      window.chainsim.game.state.simulator.autoAdvance = true
      window.chainsim.game.state.simulator.started = true
      window.chainsim.game.state.simulator.speed = 1
      window.chainsim.game.simulateLink()
    }
  }

  return (
    <Card style={cardStyle}>
      <ContainerDiv ref={gameContainer} onClick={grabChainsim}>
        <div
          ref={gameNode}
          onClick={playSim}
          style={{
            height: `${simHeight}px`,
            width: `${simWidth}px`,
            marginBottom: `4px`,
            position: `relative`,
          }}
        >
          {/* <div
            style={{
              backgroundColor: `black`,
              position: `absolute`,
              height: `100px`,
            }}
          >
            {" "}
            okaosdijf{" "}
          </div> */}
          <img
            src={imgsrc}
            width={simWidth}
            height={simHeight}
            alt=""
            style={{
              width: isGenerated && !holdingChainsim ? `${simWidth}px` : 0,
              height: isGenerated && !holdingChainsim ? `${simHeight}px` : 0,
              position: `absolute`,
              // overflow: `hidden`,
            }}
          />
          {!knowsPageLoaded && (
            <LoadingBox>
              <img
                className="load-wheel"
                src="/sim_assets/img/save_wheel.png"
                alt=""
              />{" "}
              <div>Loading! Please wait.</div>
            </LoadingBox>
          )}
        </div>
        {useCaption && (
          <Container fluid style={{ padding: 0 }}>
            <Row noGutters className="align-items-start justify-content-center">
              {props.slideshow && (
                <Col xs="auto">
                  <img
                    src="/sim_assets/img/picker_arrow_left.png"
                    alt="Prev Slide"
                    onClick={gotoPrevSlide}
                  />
                </Col>
              )}
              <Col>
                <CaptionBox>
                  {props.title !== undefined && props.link !== undefined ? (
                    <div>
                      <span role="img" aria-label="Link">
                        🔗
                      </span>
                      <a
                        href={props.link}
                        style={{ color: `white`, textDecoration: `underline` }}
                      >
                        {props.title}
                      </a>
                    </div>
                  ) : (
                    <span>{caption}</span>
                  )}
                  {props.slideshow && (
                    <div>
                      {slide + 1} / {json.fields.length}
                    </div>
                  )}
                </CaptionBox>
              </Col>
              {props.slideshow && (
                <Col xs="auto">
                  <img
                    src="/sim_assets/img/picker_arrow_right.png"
                    alt="Next Slide"
                    onClick={gotoNextSlide}
                  />
                </Col>
              )}
            </Row>
          </Container>
        )}
      </ContainerDiv>
    </Card>
  )
}

export default ChainsimContainer
