export default class CoreSettings {
  /**
   * Creates a CoreSettings object. Loads modern Tsu settings by default.
   * @param {number} rows - Number of field rows (including hidden row)
   * @param {number} cols - Number of field columns
   * @param {number} hiddenRows - Number of hidden rows
   * @param {number} targetPoint - Score needed to send 1 garbage
   * @param {number} puyoToPop - Number of Puyos in one group needed to pop
   * @param {string} garbageInHiddenRowBehavior - In SEGA games, garbage can be cleared if colored Puyos are popped underneath them in the visible rows. In COMPILE Puyo, garbage can't be cleared that way.
   * @param {number[]} chainPower - Power table array
   * @param {number[]} colorBonus - Color bonus array
   * @param {number[]} groupBonus - Group bonus array
   * @param {number} pointPuyo - Point Puyo value
   */
  constructor(
    rows = 13,
    cols = 6,
    hiddenRows = 1,
    targetPoint = 70,
    puyoToPop = 4,
    garbageInHiddenRowBehavior = "sega",
    chainPower = [
      0,
      8,
      16,
      32,
      64,
      96,
      128,
      160,
      192,
      224,
      256,
      288,
      320,
      352,
      384,
      416,
      448,
      480,
      512,
      544,
      576,
      608,
      640,
      672,
    ],
    colorBonus = [0, 3, 6, 12, 24],
    groupBonus = [0, 2, 3, 4, 5, 6, 7, 10],
    pointPuyo = 50
  ) {
    this.rows = rows
    this.cols = cols
    this.hiddenRows = hiddenRows
    this.targetPoint = targetPoint
    this.puyoToPop = puyoToPop
    this.garbageInHiddenRowBehavior = garbageInHiddenRowBehavior
    this.chainPower = chainPower
    this.colorBonus = colorBonus
    this.groupBonus = groupBonus
    this.pointPuyo = pointPuyo
  }
}
