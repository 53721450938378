import React from "react"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"

const GuideFigureThumbnail = props => {
  
  return (
    <div style={{ display: `inline-block` }}>
      <Card
        style={{
          borderColor: `rgba(0, 0, 0, 0)`,
          margin: `0 8px 12px 8px`,
        }}
      >
        <Card.Img
          variant="top"
          src={props.imgsrc}
          style={{
            display: `block`,
            maxWidth: `300px`,
            maxHeight: `160px`,
            width: `auto`,
            height: `auto`,
          }}
        />
        <Card.Text
          style={{ padding: `4px`, color: `gray`, fontSize: `0.8em` }}
        >
          {props.caption}
        </Card.Text>
      </Card>
    </div>
  )
}

GuideFigureThumbnail.propTypes = {
  imgsrc: PropTypes.string,
  caption: PropTypes.string
}

export default GuideFigureThumbnail