import React, { Component, createRef } from "react"
import CoreSettings from "../chainsim-full/chainsim_functions/CoreSettings"
import { PuyoType } from "../chainsim-full/chainsim_functions/Puyo"
import { createUniformArray } from "../chainsim-full/chainsim_functions/MatrixFunctions"

export default class ChainsimFloater extends Component {
  constructor(props) {
    super(props)

    const settings = new CoreSettings()
    let json
    if (this.props.json === undefined) {
      json = {
        title: "",
        author: "",
        player: "",
        date: "",
        category: "",
        description: "",
        outputFolder: "",
        seedVersion: 2,
        settings: settings,
        chainLength: 0,
        tags: [],
        seed: Math.round(Math.random() * 65535 * 2424),
        gameColorCount: 4,
        gameColors: [
          PuyoType.Red,
          PuyoType.Green,
          PuyoType.Blue,
          PuyoType.Yellow,
        ],
        overrideQueue: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        fields: [
          {
            comment: "",
            puyo: createUniformArray("0", settings.cols, settings.rows),
            shadow: createUniformArray("0", settings.cols, settings.rows),
            arrow: createUniformArray("0", settings.cols, settings.rows),
            cursor: createUniformArray("0", settings.cols, settings.rows),
            number: createUniformArray("0", settings.cols, settings.rows),
            placement: { coord: [], colors: [] },
            queuePosition: 0,
          },
        ],
      }
    } else {
      json = {
        title: "",
        author: "",
        player: "",
        date: "",
        category: "",
        description: "",
        outputFolder: "",
        seedVersion: 2,
        settings: settings,
        chainLength: 0,
        tags: [],
        seed: Math.round(Math.random() * 65535 * 2424),
        gameColorCount: 4,
        gameColors: [
          PuyoType.Red,
          PuyoType.Green,
          PuyoType.Blue,
          PuyoType.Yellow,
        ],
        overrideQueue: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        fields: [
          {
            comment: "",
            puyo: this.props.json.puyo,
            shadow: this.props.json.shadow,
            arrow: this.props.json.arrow,
            cursor: this.props.json.cursor,
            number: this.props.json.number
              ? this.props.json.number
              : createUniformArray("0", settings.cols, settings.rows),
            placement: { coord: [], colors: [] },
            queuePosition: 0,
          },
        ],
      }
    }

    this.state = {
      json: json,
    }

    this.testNode = createRef()
  }

  componentDidMount() {
    window.chainsimLoaded = false

    import("./ChainsimMini.js")
      .then(module => {
        const ChainsimMini = module.default
        window.chainsim = {
          game: new ChainsimMini(
            this.state.json,
            this.props.sharedLoader,
            this.props.setFloaterLoaded
          ),
        }
      })
      .then(() => {
        const resize = () => {
          window.chainsim.game.app.view.style.height = `445px`
          window.chainsim.game.app.view.style.width = `218px`
        }
        resize()
        // console.log("Resized floater sim")
      })
    // .then(() => {
    //   if (this.props.sharedLoader) this.testNode.current.appendChild(window.chainsim.game.app.view)
    // })
  }

  componentWillUnmount() {
    window.chainsimLoaded = false
    window.chainsim.game.clearTextureCache()
    window.chainsim = null
    window.chainsimInUse = null
    window.thumbnailQueue = null
    window.chainsimLoaded = null
  }

  render() {
    return <div ref={this.testNode} />
  }
}
